import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
/* Helpers */
import config from "../../../helpers/config";
import history from "../../../helpers/history";
import utils from "../../../helpers/utils";
/* Redux */
import { setCodes, setVoucherToken } from "../../../redux/actions";
/* Components */
import Footer from "../../Shared/Footer";
import Search from "../../Shared/Search";
/* CSS */
import "./Home.css";
import "./SearchBox.css";

const mapDispatchToProps = dispatch => {
  return {
    setCodes: codes => dispatch(setCodes(codes)),
    setVoucherToken: token => dispatch(setVoucherToken(token))
  };
};

class Home extends Component {
  componentDidMount() {
    const key = utils.getUrlParameter('key');
    const token = localStorage.getItem("token");
    
    if (key) {
      this.getTokenFromKey(key);
    } else {
      if (window.location.search.includes('key=')) {
        localStorage.removeItem('token');
        history.push('/controlla-mail');
      } else if (token) {
        this.checkVoucherExistence(token);
      } else {
        history.push('/controlla-mail');
      }
    }
  }

  getTokenFromKey = (key) => {
    const data = {
      token: key
    }
    
    utils.handleAPIRequest(
      {
        method: "post",
        url: config.getTokenFromKey,
        headers: { Authorization: config.token_statico },
        data: data
      },
        this.handleGetTokenFromKeySuccess,
        this.handleGetTokenFromKeyError
    );
  }

  checkVoucherExistence = (token) => {
    utils.handleAPIRequest(
      {
        method: "post",
        url: config.checkVoucherExistenceAPI,
        headers: { Authorization: token },
      },
        this.handleCheckVoucherExistenceSuccess,
        this.handleCheckVoucherExistenceError
    );
  }

  /* CALLBACKS - GET TOKEN FROM KEY REQUEST */

  handleGetTokenFromKeySuccess = response => {
    localStorage.setItem('token', response.data.token);
    this.checkVoucherExistence(response.data.token);
  }

  handleGetTokenFromKeyError = response => {
    history.push('/controlla-mail');
  }

  /* CALLBACKS - CHECK VOUCHER EXISTENCE REQUEST */

  handleCheckVoucherExistenceSuccess = response => {
    const codici = response.data.codici;
    const movimenti = response.data.movimenti;
    
    if (codici.length) {
      if (!codici[0].dataRiscatto) {
        this.props.setCodes(response.data.codici);
      } else {
        if (movimenti.length) {
          const startIndexOfToken = movimenti[0].voucherUrl.lastIndexOf('=') + 1;
          const voucherToken = movimenti[0].voucherUrl.slice(startIndexOfToken);
          this.props.setVoucherToken(voucherToken);
          history.push('/thankyou');
        }
        else {
          history.push('/controlla-mail');
        }
      }
    } else {
      history.push('/controlla-mail');
    }
  }

  handleCheckVoucherExistenceError = response => {
    history.push('/controlla-mail');
  }

  render() {
    return (
      <Fragment>
        <main className="home">
          <div className="home__body background background--home">
            <div className="search-box home__search-box">
              <img className="search-box__2x1_icon" src="/img/2x1_icon.png" alt=""></img>
              <h1 className="search-box__title">Ti regaliamo <span>un'experience</span></h1>
              <h2 className="search-box__subtitle">Scopri le esperienze 2X1* disponibili in tutta Italia. Per te un weekend, una cena al ristorante, una giornata in una spa o una giornata all'aperto!</h2>
              <Search
                element={"home"}
                class="search-box__form"
                label="Scegli l'indirizzo o la provincia che ti interessa"
                filters={config.categoriesFase1}
                redirectUrl="/scegli-esperienza"
                direction="row"
              />
            </div>
          </div>
        </main>
        <Footer note="* Un’esperienza gratuita a fronte di un’altra pagata a prezzo intero." />
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(Home);
