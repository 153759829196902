
const environment = 'production';

let BASE_URL;
switch (environment) {
    case 'development':
        BASE_URL = 'http://localhost:7071';
        break;
    case 'collaudo':
        BASE_URL = '//tlcgeneric-col.azurewebsites.net';
        break;
    case 'production':
        BASE_URL = '//tlcgeneric-prod.azurewebsites.net';
        break;
    default:
        BASE_URL = 'http://localhost:7071';
}   

const config = {
    // categoriesFase1: ['2per1 Cinema', 'Esperienza ristoranti', 'Esperienza days out'],
    categoriesFase1: [],
    GTM_Code: 'GTM-WSXRD8B',
    token_statico: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDYW1wYWduYSI6Imp0aSIsIm5iZiI6MTU4MzMxOTQ5MSwiZXhwIjoxODk4Njc5NDkxLCJpYXQiOjE1ODMzMTk0OTF9.Y812SFIAFDP03mAYs7eZHNo33-0vlgvaASp1sLPRVpM',
    
    BASE_URL,
    reCaptchaKey: '6LdntN4UAAAAAFwpnMz4rh33_LtpTsDz6eCGWdNF',
    loadAliasAPI: `${BASE_URL}/api/ListaAlias`,
    loadStructuresAPI: `${BASE_URL}/api/ListaStrutture`,
    checkVoucherExistenceAPI: `${BASE_URL}/api/Saldo`,
    downloadPdfAPI: `${BASE_URL}/api/VoucherToPDF2`,

    /* Custom per campagna */
    requireExperienceOneShotAPI: `${BASE_URL}/api/RichiediOneShot`,
    getTokenFromKey: `${BASE_URL}/api/SsoFromSha`,
}

export default config;
