import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
/* Helpers */
import config from '../../../../../helpers/config';
import utils from '../../../../../helpers/utils';
/* Redux */
import {
  setMapCoordinates,
  setMapExperiences,
  setMapAnnotationsList,
  setMapSelectedAnnotation,
  setVoucherExperience,
  setIsExternalSearch,
} from '../../../../../redux/actions';
/* Components */
import Experiences from '../ListaEsperienze/index';
/* CSS */
import './Mappa.css';

const mapDispatchToProps = dispatch => {
  return {
    setCoordinates: coordinates => dispatch(setMapCoordinates(coordinates)),
    setExperiences: experiences => dispatch(setMapExperiences(experiences)),
    setMapAnnotationsList: annotationsList => dispatch(setMapAnnotationsList(annotationsList)),
    setMapSelectedAnnotation: selectedAnnotation => dispatch(setMapSelectedAnnotation(selectedAnnotation)),
    setVoucherExperience: experience => dispatch(setVoucherExperience(experience)),
    setIsExternalSearch: bool => dispatch(setIsExternalSearch(bool)),
  };
};

const mapStateToProps = state => {
  const { mappa, voucher, helpers } = state;
  return { mappa, voucher, helpers };
};

let appleMap;

class AppleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goback: false,
      annotationList: {},
      cat_filters: [],
      previousPinSelected: '',
    };
    this.renderMarkers = this.renderMarkers.bind(this);
    this.findVenues = this.findVenues.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
    this.filterVenuesByCat = this.filterVenuesByCat.bind(this);
    this.handleBoxSelection = this.handleBoxSelection.bind(this);
    this.geocodeAndFindVenues = this.geocodeAndFindVenues.bind(this);
  }

  componentDidMount() {
    if (!this.props.mappa.coordinate) {
      this.setState({
        goback: true
      });
      return false;
    }
    let target = document.getElementById('mappa');
    appleMap = window.am.renderMap(target, {
      latitude: this.props.mappa.coordinate.Lat,
      longitude: this.props.mappa.coordinate.Lng,
      // latitudeDelta: 0.125,
      // longitudeDelta: 0.125
      latitudeDelta: 0.1,
      longitudeDelta: 0.1,
    });

    if (this.props.helpers.isExternalSearch) {
      const lat = appleMap.center.latitude;
      const lng = appleMap.center.longitude;
      // Rapporto tra i parametri delta dell'evento appleMap e i parametri delta dell'oggetto appleMap
      const rapportoDelta = 1.015;
      const latDelta = appleMap.region.span.latitudeDelta * rapportoDelta;
      const lngDelta = appleMap.region.span.longitudeDelta * rapportoDelta;
      appleMap.renderMe();
      this.findVenues(lat, lng, latDelta / 2, lngDelta / 2);
      this.props.setIsExternalSearch(false);
    }

    let self = this;
    appleMap.addEventListener('region-change-end', function(event) {
      const target = event.target;
      const lat = target.center.latitude;
      const lng = target.center.longitude;
      const latDelta = target.region.span.latitudeDelta;
      const lngDelta = target.region.span.longitudeDelta;
      appleMap.renderMe();
      self.findVenues(lat, lng, latDelta / 2, lngDelta / 2);
    });
    this.renderMarkers();
  }
  
  componentDidUpdate() {
      const el = document.getElementById(`${this.props.voucher.esperienza.idReward}-${this.props.voucher.esperienza.idVenue}`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
  }

  zoomUp() {
    if (appleMap) {
      let center = appleMap.center;
      var newCenter = new window.mapkit.Coordinate(
        center.latitude,
        center.longitude
      );
      appleMap._impl.zoomLevel++;
      appleMap.setCenterAnimated(newCenter, true);
    }
  }

  zoomDown() {
    if (appleMap) {
      let center = appleMap.center;
      var newCenter = new window.mapkit.Coordinate(
        center.latitude,
        center.longitude
      );
      appleMap._impl.zoomLevel--;
      appleMap.setCenterAnimated(newCenter, true);
    }
  }

  filterVenuesByCat(filters) {
    this.setState({
      cat_filters: filters
    });
    this.renderMarkers(filters);
  }

  geocodeAndFindVenues(addr) {
    let self = this;

    let search = new window.mapkit.Search({
      getsUserLocation: true
    });

    search.autocomplete(addr, function(err, data) {
      if (data && data.results[0]) {
        const lat = data.results[0].coordinate.latitude;
        const lng = data.results[0].coordinate.longitude;

        let map_center = new window.mapkit.Coordinate(
          lat,
          lng
        );

        let map_region = new window.mapkit.CoordinateRegion(
          map_center,
          appleMap.region.span
        );
        appleMap.setRegionAnimated(map_region);
        if (document.documentElement.clientWidth < 500) {
          self.findVenues(lat, lng, 0.25, 0.25);
        }
      }
    });
  }

  findVenues(latitude, longitude, radiusLat, radiusLng) {
    let self = this;
    // this.clearMarkers();
    self.props.setExperiences([]);
    axios({
      method: 'post',
      url: config.loadStructuresAPI,
      headers: { Authorization: localStorage.getItem('token') },
      data: {
        Lat: latitude,
        Lng: longitude,
        Alias: config.categoriesFase1,
        radiusLat: radiusLat * 0.9,
        radiusLng: radiusLng * 0.9,
      },
    })
      .then(function(res) {
        if (res.status === 200 && res.data.result === 'OK') {
          const esperienze = utils.setVenues(res.data.esperienze);

          self.props.setCoordinates({
            Lat: latitude,
            Lng: longitude
          });

          self.props.setExperiences(esperienze);

          self.renderMarkers();
        }
      })
      .catch(function(err) {
        // toastr.error('Errore: Strutture non trovate');
      });
  }

  handleBoxSelection(experience) {
    this.props.setVoucherExperience(experience);
  }

  renderMarkers(filters) {
    let annotationsList = {};
    let self = this;

    // Reset all pins
    self.clearMarkers();
    this.props.setMapAnnotationsList({});

    const activeFilters = filters || this.state.cat_filters || [];

    for (let experienceGroupIndex in this.props.mappa.esperienze) {
      const experienceGroup = this.props.mappa.esperienze[experienceGroupIndex];
      if (experienceGroup[0]) {
        const experience = experienceGroup[0];

        // If there are no active filters or the experience category is an active filter, draw the marker
        if (!activeFilters.length || (activeFilters.length && activeFilters.indexOf(experience.rewardAlias) !== -1)) {
          var pinPosition = new window.mapkit.Coordinate(
            experience.latitude,
            experience.longitude
          ); //Coordinate del nostro marker
  
          const customPin = {
            url: { 1: "/img/Pin.png"},
            anchorOffset: new DOMPoint(0, -16),
            data: experience,
          }
  
          const annotation = new window.mapkit.ImageAnnotation(pinPosition, customPin);
          
          if (!annotationsList.hasOwnProperty(experience.venueId)) {
            annotationsList = Object.assign({}, annotationsList, {
              [experience.venueId]: annotation,
            });

            appleMap.addAnnotation(annotation);
          }

          /*** EVENT HANDLERS ***/
          // Handle selected pin highlight
          annotation.addEventListener('select', () => {
            const currentPinId = annotation.data.venueId;
  
            Object.keys(this.props.mappa.annotations.list).map(pin => {
              if (this.props.mappa.annotations.list[pin].selected) {
                this.props.mappa.annotations.list[pin].url = { 1: "/img/Pin-Select.png"};
              } else if (pin === this.props.mappa.annotations.selected) {
                this.props.mappa.annotations.list[pin].url = { 1: "/img/Pin.png"};
              }

              return pin;
            });
  
            self.props.setVoucherExperience({
              idVenue: annotation.data.venueId,
              idReward: annotation.data.rewardId,
            });
  
            this.props.setMapSelectedAnnotation(currentPinId);
          });
        }
      }
    }

    this.props.setMapAnnotationsList(annotationsList);
  }

  clearMarkers() {
    for (let al in this.props.mappa.annotations.list) {
      const item = this.props.mappa.annotations.list[al];
      appleMap.removeAnnotation(item);
    }
  }

  render() {
    const experiences = this.props.mappa.esperienze
      ? this.props.mappa.esperienze.slice()
      : [];

    return (
      <div className="map-container" id="contenitore-mappa">
        <div className="mappa" id="mappa"></div>
        <Experiences
          experiences={experiences || []}
          handleBoxSelection={this.handleBoxSelection}
          active_cat_filters={this.state.cat_filters || []}
          filterVenuesByCat={this.filterVenuesByCat}
          searchLocation={this.geocodeAndFindVenues}
          handleModalOpen={this.props.handleModalOpen}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppleMap);
