import React, { Component, Fragment } from "react";
/* Components */
import Header from "../../Shared/Header";
import Footer from "../../Shared/Footer";
/* CSS */
import "./ComeFunziona.css";

class ComeFunziona extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <main className="come-funziona">
          <div className="come-funziona__body background background--come-funziona">
            <h1 className="come-funziona__title">Come funziona</h1>
            <div className="come-funziona__info-box-wrapper">
              <div className="info-box come-funziona__info-box">
                <img className="info-box__order-number-icon" src="/img/step1.svg" alt=""></img>
                <div className="info-box__text-wrapper">
                  <p className="info-box__text">Seleziona la <span className="u-text-highlighted">zona di interesse</span> per scoprire le offerte disponibili vicino a te.</p>
                </div>
              </div>
              <div className="info-box come-funziona__info-box">
                <img className="info-box__order-number-icon" src="/img/step2.svg" alt=""></img>
                <div className="info-box__text-wrapper">
                  <p className="info-box__text">Scegli la <span className="u-text-highlighted">struttura</span> convenzionata e richiedi il voucher.</p>
                  <p className="info-box__text">Cerchi un tipo di esperienza particolare?</p>
                  <p className="info-box__text">Filtra le offerte in base ai tuoi interessi!</p>
                </div>
              </div>
              <div className="info-box come-funziona__info-box">
                <img className="info-box__order-number-icon" src="/img/step3.svg" alt=""></img>
                <div className="info-box__text-wrapper">
                  <p className="info-box__text">Inserisci i tuoi dati e <span className="u-text-highlighted">scarica il voucher</span>.</p>
                  <p className="info-box__text">Ricorda! Prima di richiedere il voucher, contatta la struttura per conoscere la sua disponibilità e comunica di essere in possesso di un voucher TLC!</p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default ComeFunziona;
