import React from 'react';
import { connect } from 'react-redux';
import { setVoucherExperience } from '../../../../../../../../../../../redux/actions'
/* CSS */
import './FiltroLocation.css';

const mapDispatchToProps = dispatch => {
  return {
    setVoucherExperience: experience => dispatch(setVoucherExperience(experience))
  };
}

class FiltroLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.change = this.change.bind(this);
    this.filter = this.filter.bind(this);
  }

  change(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  filter(e) {
    e.preventDefault();

    const data = {
      idVenue: '',
      idReward: ''
    };

    this.props.toggleSelectableFilter(e);

    this.setState({
      open: false
    });

    if (this.state.position && this.state.position !== '') {
      this.props.searchLocation(this.state.position);
    }

    this.props.setVoucherExperience(data);
  }

  render() {
    return (
      <div className="filtro-selezionabile filtro-location filter">
        <div className={'filtro-selezionabile__wrapper ' + (this.props.locationFilter.isActive ? '' : 'is-hidden')}>
          <form onSubmit={this.filter.bind(this)} data-filter="location">
            <h4 className="filtro-selezionabile__title">Cerca per posizione</h4>
            <div className="filtro-location__input-group">
            <input
              type="text"
              className="filtro-location__field"
              placeholder="Cerca città o indirizzo"
              name="position"
              onChange={this.change}
            />
            <img className="filtro-location__input-icon" src="/img/search_icon_v2.svg" alt=""></img>
            </div>
            <div className="filtro-location__btns-wrapper">
              <button className="filtro-location__fake-btn" type="button">
                Fake
              </button>
              <button className="filtro-location__search-btn" type="submit" autoFocus>
                Cerca
              </button>
              <button className="filtro-location__close-btn" type="button" data-filter="location" onClick={e => this.props.toggleSelectableFilter(e)}>
                Chiudi
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(FiltroLocation);
