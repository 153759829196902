import React from 'react';
import { connect } from 'react-redux';
import FiltriSelezionabili from './components/Filtri/components/FiltriSelezionabili/index';
import Experience from './components/Esperienza/index';
/* CSS */
import './ListaEsperienze.css';
import config from '../../../../../helpers/config';

const mapStateToProps = state => {
  const { voucher } = state;
  return { voucher };
};

class Experiences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationFilter: {
        isActive: false
      },
      experienceFilter: {
        isActive: false
      },
      filters: {},
      filtersLimit: 10
    };
  }

  // componentDidUpdate() {
  //   const contenitoreEsperienze = document.querySelector('.lista-esperienze__esperienze');
  //   const filtriAttivi = document.querySelector('.area-filtri');
  //   const filtroLocation = document.querySelector('.filtro-location');
  //   const filtroEsperienza = document.querySelector('.filtro-esperienza');
  //   let mobileOffset = 0;

  //   /* Se è un dispositivo mobile aggiungo dell'offsetHeight al contenitore esperienze */
  //   if (document.documentElement.clientWidth < 500) {
  //     mobileOffset = 2;
  //   }

  //   /* Adatta l'altezza del contenitore esperienze a seconda dell'altezza del contenitore filtri attivi */
  //   if (filtriAttivi.offsetHeight) {
  //     if (filtroLocation) {
  //       contenitoreEsperienze.style.maxHeight =
  //       `calc(100% - 8.75rem - ${ filtriAttivi.offsetHeight }px - ${ filtroLocation.offsetHeight }px)`;
  //     } else if (filtroEsperienza) {
  //       contenitoreEsperienze.style.maxHeight =
  //       `calc(100% - 8.75rem - ${ filtriAttivi.offsetHeight }px - ${ filtroEsperienza.offsetHeight }px)`;
  //     } else {
  //       contenitoreEsperienze.style.maxHeight =
  //       `calc(100% - 8.75rem - ${ filtriAttivi.offsetHeight }px)`;
  //     }
  //   } else if (filtroLocation) {
  //     contenitoreEsperienze.style.maxHeight =
  //       `calc(100% - 7rem - ${ filtroLocation.offsetHeight }px - ${ mobileOffset }rem)`;
  //   } else if (filtroEsperienza) {
  //     contenitoreEsperienze.style.maxHeight =
  //       `calc(100% - 7rem - ${ filtroEsperienza.offsetHeight }px - ${ mobileOffset }rem)`;
  //   } else {
  //     contenitoreEsperienze.style.maxHeight =
  //       `calc(100% - 7rem - ${ filtriAttivi.offsetHeight }px - ${ mobileOffset }rem)`;
  //   }
  // }

  toggleSelectableFilter = event => {
    event.preventDefault();

    const target = event.currentTarget;
    const filter = target.dataset.filter;

    switch(filter) {
      case 'location':
        this.setState({
          locationFilter: {
            isActive: !this.state.locationFilter.isActive,
          },
          experienceFilter: {
            isActive: false,
          },
        });
        break;
      case 'experience':
        this.setState({
          locationFilter: {
            isActive: false,
          },
          experienceFilter: {
            isActive: !this.state.experienceFilter.isActive,
          },
        });
        break;
      default:
    }
  }

  closeSelectableFilter = () => {
    this.setState({
      locationFilter: {
        isActive: false,
      },
      experienceFilter: {
        isActive: false,
      },
    });
  }

  /* RealFilters sono i filtri che effettivamente "filtrano" le esperienze, Filters sono i controlli grafici --> rinominare per rendere il tutto più capibile */
  updateRealFilters = () => {
    const activeFilters = [];
    // const filtriAttivi = document.querySelector('.area-filtri');

    Object.keys(this.state.filters).map(elem => {
      if (this.state.filters[elem]) {
        activeFilters.push(elem);
      }

      return elem;
    });

    this.props.filterVenuesByCat(activeFilters);

    // if (activeFilters.length) {
    //   filtriAttivi.classList.add('is-active');
    // } else {
    //   filtriAttivi.classList.remove('is-active');
    // }
  };

  handleFilters = (filter, checked = false) => {
     if (config.categoriesFase1.length === 0 || config.categoriesFase1.includes(filter)) {
       if (!checked || this.countSelectableFilters() < this.state.filtersLimit) {
         this.setState({
           filters: {
             ...this.state.filters,
             [filter]: checked
           }
         });
       }
     }
  };

  handleFiltersAndUpdate = (filter, checked = false) => {
    if (config.categoriesFase1.includes(filter)) {
      if (!checked || this.countSelectableFilters() < this.state.filtersLimit) {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              [filter]: checked
            }
          },
          this.updateRealFilters
        );
      }
    }
  };

  countSelectableFilters = () => {
    let counter = 0;

    Object.keys(this.state.filters).map(elem => {
      if (this.state.filters[elem]) {
        counter++;
      }

      return elem;
    });

    return counter;
  };

  clearAllFilters = () => {
    const newFilters = Object.assign({}, this.state.filters);

    for (let property in newFilters) {
      newFilters[property] = false;
    }

    this.setState(
      {
        filters: newFilters
      },
      () => this.updateRealFilters()
    );
  };

  render() {
    let self = this;
    return (
      <div className="lista-esperienze">
        <div className="lista-esperienze__filtri">
          <FiltriSelezionabili
            searchLocation={this.props.searchLocation}
            experiences={this.props.experiences}
            filters={this.state.filters}
            filtersLimit={this.state.filtersLimit}
            filterVenuesByCat={this.props.filterVenuesByCat}
            handleFilters={this.handleFilters}
            locationFilter={this.state.locationFilter}
            experienceFilter={this.state.experienceFilter}
            toggleSelectableFilter={this.toggleSelectableFilter}
            handleFiltersAndUpdate={this.handleFiltersAndUpdate}
            clearAllFilters={this.clearAllFilters}
          />
        </div>
        <div className="lista-esperienze__esperienze">
          {this.props.experiences.map(function(e, i) {
            const hasAlias = e && e[0] && e[0].rewardAlias !== '';
            const alias = e[0].rewardAlias;
            if (!hasAlias) {
              //Non ha un alias, non è un prodotto valido
              return false;
            } else if (
              self.props.active_cat_filters.length > 0 &&
              self.props.active_cat_filters.indexOf(alias) === -1
            ) {
              //Se ci sono filtri e il nostro alias non è nei filtri
              return false;
            }
            return (
              <Experience
                key={i}
                experience={e}
                currentExperience={e[0]}
                handleBoxSelection={self.props.handleBoxSelection}
                handleModalOpen={self.props.handleModalOpen}
                isSelected={(self.props.voucher.esperienza.idReward === e[0].rewardId && self.props.voucher.esperienza.idVenue === e[0].venueId) ? true : false}
                closeSelectableFilter={self.closeSelectableFilter}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Experiences);
