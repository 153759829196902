import React, { Component } from "react";
/* Helpers */
import history from "../../../helpers/history";
/* Redux */
import { connect } from "react-redux";
import { setMapCoordinates, setMapExperiences, setIsExternalSearch } from "../../../redux/actions";
/* CSS */
import "./Search.css";

const mapDispatchToProps = dispatch => {
  return {
    setMapCoordinates: coordinates => dispatch(setMapCoordinates(coordinates)),
    setMapExperiences: experiences => dispatch(setMapExperiences(experiences)),
    setIsExternalSearch: bool => dispatch(setIsExternalSearch(bool)),
  };
};

class Search extends Component {
  state = {
    gomap: false,
    position: "",
    key: "11a67b4e375600",
    display_name: "",
    lat: "",
    lng: "",
    geocodeResults: "",
    stateButton: false,
    auth: "",
    isIE11: false,
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.setState({
        auth: token
      });
    }

    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.setState({
        isIE11: true
      });
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    switch (target.type) {
      case "checkbox":
        this.setState({
          [name]: target.checked
        });
        break;
      case "radio":
        this.setState({
          [name]: value === "true" ? true : false
        });
        break;
      default:
        this.setState({
          [name]: value
        });
    }
  };

  searchVenues = event => {
    event.preventDefault();
    const pos = this.state.position;
    let self = this;
    if (pos && pos !== "") {
      let italian_pos = pos;
      /*if (pos && pos.toLowerCase().indexOf("italia") === -1) {
        italian_pos = "Italia, " + pos;
      }*/

      let search = new window.mapkit.Search({
        getsUserLocation: true
      });

      search.autocomplete(italian_pos, function(err, data) {
        if (data && data.results[0]) {
          const lat = data.results[0].coordinate.latitude;
          const lng = data.results[0].coordinate.longitude;

          self.props.setMapCoordinates({
            Lat: lat,
            Lng: lng
          });

          self.props.setIsExternalSearch(true);

          history.push(self.props.redirectUrl);
        }
      });
    }
  };

  render() {
    const { isIE11 } = this.state;

    return (
      <form
        className={`search search--${this.props.element} ${
          this.props.direction === "column" ? "search--direction-column" : ""
        } ${this.props.class} ${ isIE11 ? 'search--is-IE11' : '' }`}
        onSubmit={this.searchVenues.bind(this)}
      >
        <label className="search__label">{this.props.label}</label>
        <i className="search__icon fas fa-search"></i>
        <input
          className="search__field"
          type="text"
          id="main-position"
          name="position"
          placeholder="Scegli città o indirizzo"
          value={this.state.position}
          onChange={this.handleChange}
          autoComplete="off"
        />
        <button
          type="submit"
          className="search__submit-btn"
        >
          <img className="search__submit-icon" src="/img/search_icon.svg" alt=""></img>
        </button>
      </form>
    );
  }
}

export default connect(null, mapDispatchToProps)(Search);
