import React, { Component } from "react";
/* CSS */
import "./Tutorial.css";

class Tutorial extends Component {
  state = {
    overlayIsActive: true,
    activePopover: 1,
    totalPopover: 3,
    isIE11: false,
  };

  componentDidMount() {
    if (localStorage.getItem("tutorialCompleted")) {
      this.setState({
        overlayIsActive: false
      });
    }

    // Check if is IE11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.setState({
        isIE11: true
      });
    }
  }

  handleClick = () => {
    if (this.state.activePopover < this.state.totalPopover) {
      this.setState({
        activePopover: this.state.activePopover + 1
      });
    } else {
      this.setState({
        overlayIsActive: false
      });
      localStorage.setItem("tutorialCompleted", true);
    }
  };

  render() {
    const { overlayIsActive, activePopover, isIE11 } = this.state;

    return (
      <div
        className={`tutorial opaque-overlay opaque-overlay--no-mobile ${
          overlayIsActive && !isIE11 ? "opaque-overlay--is-active" : ""
        }`}
      >
        <div
          className={`custom-popover ${
            activePopover === 1 ? "custom-popover--is-active" : ""
          }`}
        >
          <div className="custom-popover__text-wrapper">
            <p className="custom-popover__number">1.</p>
            <p className="custom-popover__text">Ciao! Naviga sulla mappa e scopri le esperienze disponibili
            nell'area geografica che hai scelto.</p>
          </div>
          <button
            className="custom-popover__btn"
            type="button"
            onClick={this.handleClick}
          >
            Continua
          </button>
        </div>
        <div
          className={`custom-popover custom-popover--center ${
            activePopover === 3 ? "custom-popover--is-active" : ""
          }`}
        >
          <div className="custom-popover__text-wrapper">
            <p className="custom-popover__number">3.</p>
            <p className="custom-popover__text">Ricorda! Prima di richiedere il voucher, contatta la struttura
            per conoscere la sua disponibilità e comunica di essere in possesso
            di un voucher TLC!</p>
          </div>
          <button
            className="custom-popover__btn"
            type="button"
            onClick={this.handleClick}
          >
            Chiudi
          </button>
        </div>
        <div
          className={`custom-popover ${
            activePopover === 2 ? "custom-popover--is-active" : ""
          }`}
        >
          <div className="custom-popover__text-wrapper">
            <p className="custom-popover__number">2.</p>
            <p className="custom-popover__text">Ecco l’elenco delle strutture che offrono esperienze sul
            territorio da te selezionato. Cerchi un tipo di esperienza
            particolare? Filtra le offerte in base ai tuoi interessi!</p>
          </div>
          <button
            className="custom-popover__btn"
            type="button"
            onClick={this.handleClick}
          >
            Continua
          </button>
        </div>
      </div>
    );
  }
}

export default Tutorial;
