import React, { Fragment } from 'react';
/* CSS */
import './FakeCheckbox.css';

class FakeCheckbox extends React.Component {
  handleCheck = () => {
    this.props.focus_func();
    this.props.handleChange(this.props.filter, !this.props.checked);
  };

  render() {
    return (
      <Fragment>
        <input className="fake-checkbox__input" type="checkbox" id={ this.props.name + this.props.index } name={this.props.name} checked={this.props.checked} onClick={this.handleCheck} />
        <label className="fake-checkbox__label" for={ this.props.name + this.props.index }>{ this.props.filter }</label>
      </Fragment>
    );
  }
}

export default FakeCheckbox;
