import React, { Component, Fragment } from 'react';
/* Components */
import FiltroEsperienza from './components/FiltroEsperienza/index';
import FiltroLocation from './components/FiltroLocation/index';
/* CSS */
import './FiltriSelezionabili.css';

class FiltriSelezionabili extends Component {
  render() {

    let filtroAttivo = null;
    
    if (this.props.locationFilter.isActive) {
      filtroAttivo = (
        <FiltroLocation 
          searchLocation={this.props.searchLocation}
          locationFilter={this.props.locationFilter}
          toggleSelectableFilter={this.props.toggleSelectableFilter}
        />
      );
    } else if (this.props.experienceFilter.isActive) {
      filtroAttivo = (
        <FiltroEsperienza
          experiences={this.props.experiences}
          filters={this.props.filters}
          filtersLimit={this.props.filtersLimit}
          filterVenuesByCat={this.props.filterVenuesByCat}
          handleFilters={this.props.handleFilters}
          experienceFilter={this.props.experienceFilter}
          toggleSelectableFilter={this.props.toggleSelectableFilter}
          handleFiltersAndUpdate={this.props.handleFiltersAndUpdate}
          clearAllFilters={this.props.clearAllFilters}
        />
      );
    }

    return (
      <Fragment>
        <section className="filtri-selezionabili">
            <div className="filtri-selezionabili__location-wrapper">
              <div className="filtri-selezionabili__toggle-btn left" data-filter="location" onClick={e => this.props.toggleSelectableFilter(e)}>
                seleziona indirizzo
              </div>
              <button className="filtri-selezionabili__plus-btn" data-filter="location" onClick={e => this.props.toggleSelectableFilter(e)}>{ !this.props.locationFilter.isActive ? (<img src="/img/plus.svg" alt=""></img>) : (<img src="/img/minus.svg" alt=""></img>) }</button>
            </div>
            <div className="filtri-selezionabili__experience-wrapper">
              <div className="filtri-selezionabili__toggle-btn" data-filter="experience" onClick={e => this.props.toggleSelectableFilter(e)}>
                esperienze
              </div>
              <button className="filtri-selezionabili__plus-btn" data-filter="experience" onClick={e => this.props.toggleSelectableFilter(e)}>{ !this.props.experienceFilter.isActive ? (<img src="/img/plus.svg" alt=""></img>) : (<img src="/img/minus.svg" alt=""></img>) }</button>
            </div>
        </section>
        { filtroAttivo }
      </Fragment>
    );
  }
}

export default FiltriSelezionabili;