import React, { Component } from 'react';
import { Link } from 'react-router-dom';
/* CSS */
import './Footer.css';

class Footer extends Component {
  render() {
    let faqPage;

    if (this.props.fase && this.props.fase === 2) {
      faqPage = '/faq';
    } else {
      faqPage = '/come-funziona';
    }

    return (
      <footer className="footer">
        <p className={`footer__note ${ !this.props.note ? 'footer__note--is-hidden' : ''}`}>{ this.props.note }</p>
        <div className="footer__link-wrapper">
          <a className="footer__link" href="/pdf/Regolamento_Trasparenza_JTI.pdf" target="_blank">REGOLAMENTO</a>
          <Link className="footer__link" to={'/come-funziona'}>COME FUNZIONA</Link> 
        </div>
        <p className="footer__text">
        Iniziativa promozionale valida dal 30/07/22 al 29/07/24. L’offerta è soggetta a disponibilità promozionale sul territorio e a termini e condizioni. Leggili attentamente prima di partecipare. Regolamento completo consultabile su <a className="u-text-highlighted" href="www.ploom2x1.it" target="_blank">www.ploom2x1.it</a>. Per maggiori informazioni scrivi a <a className="u-text-highlighted" href="mailto:ploom2x1@tlcrewards.com">ploom2x1@tlcrewards.com</a> o chiama lo <a className="u-text-highlighted" href="tel:+39 02 3045 3977">02 3045 3977</a> (da lun a ven, dalle 9.30 alle 13 e dalle 14 alle 17.30).
        </p>
      </footer>
    );
  }
}

export default Footer;