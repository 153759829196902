import React, { Component, Fragment } from "react";
/* Components */
import Footer from "../../Shared/Footer";
/* CSS */
import "./ControllaMail.css";

class ControllaMail extends Component {
  render() {
    return (
      <Fragment>
        <main className="check-mail">
          <div className="check-mail__body background background--check-mail">
            <div className="search-box check-mail__search-box">
              <img className="check_email__2x1_icon" src="/img/2x1_icon.png" alt=""></img>
              <h1 className="search-box__title">Ti regaliamo un'experience</h1>
              <h2 className="search-box__subtitle">Attenzione, non puoi accedere direttamente a questo sito, usa il link che hai ricevuto nella mail di invito per accedere. Se lo hai perso scrivi a <a className="u-text-highlighted" href="mailto:ploom2x1@tlcrewards.com">ploom2x1@tlcrewards.com</a> per ricevere nuovamente la mail.</h2>
            </div>
          </div>
        </main>
        <Footer note="* Un’esperienza gratuita a fronte di un’altra pagata a prezzo intero." />
      </Fragment>
    );
  }
}

export default ControllaMail;
