import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
/* Helpers */
import config from '../../../helpers/config';
import history from '../../../helpers/history';
import utils from '../../../helpers/utils';
/* Redux */
import { setVoucherToken } from '../../../redux/actions';
/* Components */
import Header from '../../Shared/Header/index';
import Footer from '../../Shared/Footer/index';
import Registration from './components/Registrazione/index';
/* CSS */
import './ScaricaVoucher.css';

const mapStateToProps = state => {
  const { voucher, codici } = state;
  return { voucher, codici };
};

const mapDispatchToProps = dispatch => {
  return {
    setVoucherToken: token => dispatch(setVoucherToken(token))
  };
};

class ScaricaVoucher extends Component {
  state = {
    user: {
      birthDate: '',
      job: {
        name: 'Professione',
        value: '',
      },
      hobby: {
        name: 'Interessi',
        value: '',
      },
      privacy: false,
    },
    errors: {
      birthDateError: '',
      jobError: '',
      hobbyPromoError: '',
      privacyError: '',
    },
    serverError: '',
    serverSuccess: '',
    btnClicked: false,
    openDropdown: '',
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    
    if (token) {
      this.verifyExperienceDataExistence();
      this.verifyCodeExistence();
    } else {
      history.push('/controlla-mail');
    }
  }

  verifyExperienceDataExistence = () => {
    if (!this.props.voucher.esperienza.idVenue || !this.props.voucher.esperienza.idReward) {
      history.push('/');
    }
  }

  // E' una verifica in più che potrebbe sembrare inutile ma non fa male a nessuno se elaborata
  verifyCodeExistence = () => {
    if (!this.props.codici.length) {
      history.push('/');
    }
  }

  setUserData = newUserData => {
    this.setState({
      user: newUserData,
    });
  }

  handleDropdownButton = event => {
    const target = event.currentTarget;
    const dropdownSelected = target.parentNode.getAttribute('data-name');

    this.setState({
      openDropdown: (this.state.openDropdown !== dropdownSelected) ? dropdownSelected : '',
    });
  }

  handleDropdownOption = event => {
    const target = event.currentTarget;
    const dropdownSelected = target.parentNode.parentNode.getAttribute('data-name');
    const name = target.textContent;
    const value = target.getAttribute('data-value');

    this.setState({
      user: {
        ...this.state.user,
        [dropdownSelected]: {
          name,
          value,
        },
      },
      openDropdown: '',
    });
  }

  handleUserChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    switch (target.type) {
      case 'checkbox':
        this.setState({
          user: {
            ...this.state.user,
            [name]: target.checked
          }
        });
        break;
      case 'radio':
        this.setState({
          user: {
            ...this.state.user,
            [name]: (value === "true") ? true : false,
          }
        });
        break;
      default:
        this.setState({
          user: {
            ...this.state.user,
            [name]: value
          }
        });
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      btnClicked: true,
    });

    const data = {
      birthDate: utils.formatToMDYDateFormat(this.state.user.birthDate),
      job: this.state.user.job.value,
      hobby: this.state.user.hobby.value,
      // privacy: this.state.user.privacy,
      privacy: true,
      codicePromo: this.props.codici[0].codice,
      societa: 'JTI',
      RewardId: this.props.voucher.esperienza.idReward,
      VenueId: this.props.voucher.esperienza.idVenue,
    }

    const isValid = this.validateForm();
    const self = this;

    if (isValid) {
      this.props.googleReCaptchaProps.executeRecaptcha('')
      .then(token => {
        data.token = token;
        utils.handleAPIRequest(
          {
            method: "post",
            url: config.requireExperienceOneShotAPI,
            headers: { Authorization: localStorage.getItem('token') },
            data: data
          },
            self.handleSubmitSuccess,
            self.handleSubmitError,
            self.handleSubmitCatch,
        );
        this.setState({
          errors: {
            birthDateError: '',
            jobError: '',
            hobbyError: '',
            privacyError: '',
          },
        });
      })
    } else {
      this.setState({
        serverError: '',
        btnClicked: false,
      });
    }
  };

    /* CALLBACKS - ONE SHOT EXPERIENCE REQUEST */

    handleSubmitSuccess = response => {
      this.props.setVoucherToken(response.data.message)
      
      this.setState({
        serverError: '',
        serverSuccess: 'Controllo del codice promozionale in corso...',
      });
  
      setTimeout(() => {
        history.push('/thankyou');
      }, 1000);
    }
  
    handleSubmitError = response => {
      this.setState({
        serverError: response.data.message,
        serverSuccess: '',
        btnClicked: false,
      });
    }

    handleSubmitCatch = response => {
      this.setState({
        serverError: 'Si è verificato un errore. Riprova più tardi',
        serverSuccess: '',
        btnClicked: false,
      });
    }
  
  /* FORM VALIDATION */

  validateForm = () => {
    let birthDateError = '';
    let jobError = '';
    let hobbyError = '';
    let privacyError = '';
    let scrollTo = '';

    /* Birth date */

    if (!utils.formValidation.isRequired(this.state.user.birthDate)) {
      birthDateError = "Il campo è obbligatorio";
      if (!scrollTo) {
        scrollTo = 'birthDate';
      }
    }

    if (!utils.formValidation.isDateFormatValid(this.state.user.birthDate)) {
      birthDateError = "Il campo deve contenere una data nel seguente formato: DD/MM/YYYY";
      if (!scrollTo) {
        scrollTo = 'birthDate';
      }
    } else {
      if (!utils.formValidation.isDateValid(this.state.user.birthDate)) {
        birthDateError = "La data non è valida";
        if (!scrollTo) {
          scrollTo = 'birthDate';
        }
      }
    }

    /* Job */

    if (!utils.formValidation.isRequired(this.state.user.job.value)) {
      jobError = "Il campo è obbligatorio";
      if (!scrollTo) {
        scrollTo = 'birthDate';
      }
    }
    
    /* Hobby */

    if (!utils.formValidation.isRequired(this.state.user.hobby.value)) {
      hobbyError = "Il campo è obbligatorio";
      if (!scrollTo) {
        scrollTo = 'birthDate';
      }
    }

    /* Privacy */

    // if (!this.state.user.privacy) {
    if (!true) {
      privacyError = "É necessario esprimere il consenso al trattamento dati proposto";
      if (!scrollTo) {
        scrollTo = 'privacy';
      }
    }

    if (birthDateError || jobError || hobbyError || privacyError) {
      this.setState({
        errors: {
          birthDateError,
          jobError,
          hobbyError,
          privacyError,
        },
      });
      utils.scrollToElement(document.querySelector(`input[name=${scrollTo}]`));
      return false;
    }
    return true;
  }

  render() {
    return (
      <Fragment>
        <Header />
        <main className="scarica-voucher">
          <div className="background background--scarica-voucher">
            <Registration currentState={this.state} setUserData={this.setUserData} handleChange={this.handleUserChange} handleDropdownButton={this.handleDropdownButton} handleDropdownOption={this.handleDropdownOption} handleSubmit={this.handleSubmit}/>            
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

ScaricaVoucher = withGoogleReCaptcha(ScaricaVoucher)

export default connect(mapStateToProps, mapDispatchToProps)(ScaricaVoucher);
