import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import FakeCheckbox from '../../../../../../../../../../Shared/FakeCheckbox/index';
/* Helpers */
import config from '../../../../../../../../../../../helpers/config';
/* Redux */
import { setExperienceFilter } from "../../../../../../../../../../../redux/actions";
/* CSS */
import './FiltroEsperienza.css';

const mapDispatchToProps = dispatch => {
  return {
    setExperienceFilter: (updatedFilter) => dispatch(setExperienceFilter(updatedFilter)),
  };
}

const mapStateToProps = state => {
  const { filtri } = state;
  return { filtri };
}

class FiltroEsperienza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
    };
    this.filter = this.filter.bind(this);
  }

  componentDidMount() {
    if (!this.props.filtri.esperienze.aliasCaricati) {
      let cat_filters = [];
      let self = this;
      axios({
        method: 'post',
        url: config.loadAliasAPI,
        headers: { Authorization: localStorage.getItem('token') },
        data: {
          Alias: config.categoriesFase1
        },
      }).then(function(res) {
        if (res.status === 200) {
          for (let f in res.data) {
            const filter = res.data[f];
            cat_filters.push(filter);
            self.props.handleFilters(res.data[f]);
          }
          cat_filters.sort();
          self.setState({
            filters: cat_filters,
          });
          self.props.setExperienceFilter({ aliasCaricati: true });
        }
      });
    }
  }

  setFocus() {
    document.querySelector('.js-filter-btn').focus();
  }

  filter(e) {
    e.preventDefault();

    this.props.toggleSelectableFilter(e);

    const filters = [];
    // const filtriAttivi = document.querySelector('.area-filtri');

    for (const property in this.props.filters) {
      if (this.props.filters[property]) {
        filters.push(property);
      }
    }

    this.props.filterVenuesByCat(filters);

    // if (filters.length) {
    //   filtriAttivi.classList.add('is-active');
    // } else {
    //   filtriAttivi.classList.remove('is-active');
    // }
  }

  handleChange = (filter, checked) => {
    this.props.handleFilters(filter, checked);
  };

  render() {
    let self = this;

    return (
      <div className="filtro-selezionabile filtro-esperienza filter">
        <div className={'filtro-selezionabile__wrapper ' + (this.props.experienceFilter.isActive ? '' : 'is-hidden')}>
          <form onSubmit={this.filter.bind(this)} data-filter="experience">
            <h4 className="filtro-selezionabile__title">Cerca esperienze</h4>
            <ul className="filtro-esperienza__list">
              {Object.keys(this.props.filters).map(function(elem, i) {
                return (
                  <li key={i} className="filtro-esperienza__item cat-filter">
                    <FakeCheckbox
                      index={i}
                      name="filtro_esperienza"
                      filter={elem}
                      focus_func={self.setFocus}
                      handleChange={self.handleChange}
                      checked={self.props.filters[elem]}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="filtro-esperienza__btns-wrapper">
              <button className="filtro-esperienza__clear-filters-btn" onClick={ this.props.clearAllFilters }>
                Cancella filtri
              </button>
              <button className="filtro-esperienza__filter-btn js-filter-btn" type="submit">
                Filtra
              </button>
              <button className="filtro-esperienza__close-btn" data-filter="experience" onClick={e => this.props.toggleSelectableFilter(e)}>
                Chiudi
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltroEsperienza);
