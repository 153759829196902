import React from 'react';
import { connect } from 'react-redux';
/* Helpers */
import history from '../../../../../../../helpers/history';
import utils from '../../../../../../../helpers/utils';
/* Redux */
import { setVoucherExperience, setMapSelectedAnnotation } from '../../../../../../../redux/actions';
/* CSS */
import './Esperienza.css';

const mapStateToProps = state => {
  const { annotations } = state.mappa;
  return { annotations }
}

const mapDispatchToProps = dispatch => {
  return {
    setVoucherExperience: experiences => dispatch(setVoucherExperience(experiences)),
    setMapSelectedAnnotation: selectedAnnotation => dispatch(setMapSelectedAnnotation(selectedAnnotation)),
  };
};

class Experience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experience: {},
    };
  }
  componentWillMount() {
    let remote_reward = this.props.experience[0];
    let name = remote_reward.venueName;
    if (name.length >= 25) {
      name = name.substring(0, 23) + '...';
    }
    let indirizzo =
      remote_reward.address1 +
      ' - ' +
      remote_reward.postcode +
      ' ' +
      remote_reward.city;
    let indirizzo_trunc =
      remote_reward.address1 +
      ' - ' +
      remote_reward.postcode +
      ' ' +
      remote_reward.city;
    if (indirizzo_trunc.length >= 30) {
      indirizzo_trunc = indirizzo_trunc.substring(0, 27) + '...';
    }
    let href = utils.correctUrlFormat(remote_reward.website);
    let website = remote_reward.website;
    if (website.length >= 22) {
      website = website.substring(0, 19) + '...';
    }
    let tel = remote_reward.phone.replace(/ /g, '');
    if (remote_reward) {
      this.setState({
        experience: {
          id: remote_reward.rewardId,
          rewardName: remote_reward.venueName,
          rewardNameSub: remote_reward.rewardName,
          rewardNameTrunc: name,
          rewardAlias: remote_reward.rewardAlias,
          indirizzo: indirizzo,
          indirizzo_trunc: indirizzo_trunc,
          phone: tel,
          href: href,
          website: website,
          credits: remote_reward.credits,
          categoryName: remote_reward.categoryName,
          offerTerm1: this.props.experience[0].offerTerm1,
          offerTerm2: this.props.experience[0].offerTerm2,
          offerTerm3: this.props.experience[0].offerTerm3,
          offerTerm4: this.props.experience[0].offerTerm4,
          termsFile: this.props.experience[0].termsFile,
          venueId: this.props.experience[0].venueId,
          rewardId: this.props.experience[0].rewardId
        }
      });
    }
  }

  handleClick = event => {
    event.preventDefault();
    
    const data = {
      idVenue: this.props.experience.venueId,
      idReward: this.props.experience.rewardId
    };

    this.props.setVoucherExperience(data);

    history.push('/scarica-voucher');
  }

  handleSelection = event => {
    if (event.target.classList[0] !== 'esperienza__close-btn') {
      const target = event.currentTarget;
      const idVenue = target.dataset.idVenue;
      const idReward = target.dataset.idReward;

      this.props.closeSelectableFilter();

      for (const item in this.props.annotations.list) {
        if (item === idVenue) {
          this.props.annotations.list[item].selected = true;
          this.props.annotations.list[item].url = { 1: "/img/Pin-Select.png"};
        } else if (item === this.props.annotations.selected) {
          this.props.annotations.list[item].selected = false;
          this.props.annotations.list[item].url = { 1: "/img/Pin.png"};
        }
      }

      this.props.handleBoxSelection({
        idVenue,
        idReward,
      });
      this.props.setMapSelectedAnnotation(idVenue);
    }
  }

  handleCloseBox = () => {
    this.props.handleBoxSelection({
      idVenue: "",
      idReward: "",
    });
  }

  render() {
    const offerTerms = [];

    if (this.state.experience.offerTerm1) {
      offerTerms.push(this.state.experience.offerTerm1);
    }
    if (this.state.experience.offerTerm2) {
      offerTerms.push(this.state.experience.offerTerm2);
    }
    if (this.state.experience.offerTerm3) {
      offerTerms.push(this.state.experience.offerTerm3);
    }
    if (this.state.experience.offerTerm4) {
      offerTerms.push(this.state.experience.offerTerm4);
    }

    const toggledNotes = (
      <div className="esperienza__hidden-box toggle-box">
        <div className="toggle-box__text">
          {typeof offerTerms !== 'undefined' && offerTerms.length > 0 ? (
            <p className="esperienza__hidden-text">
              Note:&nbsp;
              {offerTerms.join(', ')}
            </p>
          ) : null}
          {/* <a className="esperienza__hidden-link" href={ '/EmailTemplates/ConfirmVoucher/' + this.state.experience.termsFile } target="_blank" rel="noopener noreferrer">
            Leggi i termini e condizioni del voucher
          </a> */}
        </div>
      </div>
    );

    const toggledButtons = (
      <div className="esperienza__toggle-btns-wrapper">
        <button className="esperienza__fake-btn" type="button">
          Fake
        </button>
        <button
          type="button"
          className="esperienza__submit-btn"
          onClick={e =>
            this.handleClick(e)
          }
        >
          Richiedi voucher
        </button>
        <button className="esperienza__close-btn" onClick={ this.handleCloseBox }>
          Chiudi
        </button>
      </div>
    );

    return (
      <div
        className={'esperienza experience ' + (this.props.isSelected ? 'is-selected' : '')}
        id={`${this.props.currentExperience.rewardId}-${this.props.currentExperience.venueId}`}
        data-id-venue={ this.props.currentExperience.venueId }
        data-id-reward={ this.props.currentExperience.rewardId }
        onClick={this.handleSelection}
      >
        <div className="esperienza__content-wrapper">
          <div className="esperienza__info">
            <span className="esperienza__reward-name rewardName" title={this.state.experience.rewardName}>
              {
                this.props.isSelected
                ? this.state.experience.rewardName
                : this.state.experience.rewardNameTrunc
              }
            </span>
            <div className="esperienza__badges">
              <span className="esperienza__category">
                { this.state.experience.categoryName }
              </span>
              <span className="esperienza__reward-type">
                {this.state.experience.rewardNameSub}
              </span>
            </div>
            <div className="esperienza__address" title={this.state.experience.indirizzo_trunc}>
              {this.state.experience.indirizzo}
            </div>
            <div>
              <span className="esperienza__phone">{this.state.experience.phone}</span>
              { this.state.experience.website ? ' - ' : '' }
              <a
                className="esperienza__website website"
                title={this.state.experience.website}
                href={this.state.experience.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.experience.website}
              </a>
            </div>
            {this.props.isSelected ? toggledNotes : null}
          </div>
          <div className="esperienza__category-square">
            <p>{this.state.experience.rewardAlias}</p>
          </div>
        </div>
        {this.props.isSelected ? toggledButtons : null}
        <div className="clear"></div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
