import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
/* Helpers */
import config from '../../../helpers/config';
import history from '../../../helpers/history';
import utils from '../../../helpers/utils';
/* Redux */
import { setCodes, setVoucherToken } from "../../../redux/actions";
/* Components */
import Header from '../../Shared/Header/index';
import Footer from '../../Shared/Footer/index';
import Tutorial from '../../Shared/Tutorial';
import AppleMap from './components/Mappa/index';
/* CSS */
import './ScegliEsperienza.css';

const mapDispatchToProps = dispatch => {
  return {
    setCodes: codes => dispatch(setCodes(codes)),
    setVoucherToken: token => dispatch(setVoucherToken(token))
  };
};

const mapStateToProps = state => {
  const { codici } = state;
  return { codici };
}

class ScegliEsperienza extends Component {
  state = {
    modalType: '',
    modalExperience: '',
    modalIsOpen: false
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    
    if (token) {
      this.checkCodeExistence(token);
    } else {
      history.push('/controlla-mail');
    }
  }

  checkCodeExistence = token => {
    if (!this.props.codici.length) {
      this.checkVoucherExistence(token);
    }
  }

  checkVoucherExistence = (token) => {
    utils.handleAPIRequest(
      {
        method: "post",
        url: config.checkVoucherExistenceAPI,
        headers: { Authorization: token },
      },
        this.handleCheckVoucherExistenceSuccess,
        this.handleCheckVoucherExistenceError
    );
  }

  /* CALLBACKS - CHECK VOUCHER EXISTENCE REQUEST */

  handleCheckVoucherExistenceSuccess = response => {
    const codici = response.data.codici;
    const movimenti = response.data.movimenti;
    
    if (codici.length) {
      if (!codici[0].dataRiscatto) {
        this.props.setCodes(response.data.codici);
      } else {
        if (movimenti.length) {
          const startIndexOfToken = movimenti[0].voucherUrl.lastIndexOf('=') + 1;
          const voucherToken = movimenti[0].voucherUrl.slice(startIndexOfToken);
          this.props.setVoucherToken(voucherToken);
          history.push('/thankyou');
        }
        else {
          history.push('/controlla-mail');
        }
      }
    } else {
      history.push('/controlla-mail');
    }
  }

  handleCheckVoucherExistenceError = response => {
    history.push('/controlla-mail');
  }

  render() {
    return (
      <Fragment>
        <Header />
        <Tutorial />
        <main className="scegli-esperienza">
          <AppleMap handleModalOpen={this.handleModalOpen} />
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScegliEsperienza);