import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
/* Helpers */
import config from '../../../helpers/config';
import history from '../../../helpers/history';
/* Redux */
import { setVoucherToken } from '../../../redux/actions';
/* Components */
import Header from '../../Shared/Header/index';
import Footer from '../../Shared/Footer/index';
/* CSS */
import './ThankYou.css';

const mapStateToProps = state => {
  const { voucher } = state;
  return { voucher };
};

const mapDispatchToProps = dispatch => {
  return {
    setVoucherToken: token => dispatch(setVoucherToken(token))
  };
};

class ThankYou extends Component {
  componentDidMount() {
    if (!this.props.voucher.token) {
      history.push('/');
    }
  }
  
  handleDownloadButton = () => {
    if (this.props.voucher.token) {
      window.location.href = config.downloadPdfAPI + '?TokenizedVoucher=' + this.props.voucher.token;
    }
    this.props.setVoucherToken('');
  }

  render() {
    return (
      <Fragment>
        <Header />
        <main className="thank-you">
          <div className="background background--thank-you background--center-content">
            <div className="thank-you-box">
              <h1 className="thank-you-box__title">Complimenti!</h1>
              <h2 className="thank-you-box__subtitle">La richiesta è stata completata, ora puoi scaricare il pdf del tuo Voucher. Ricordati di stampare una copia del voucher o salvarla sul tuo smartphone in modo da mostrarla al tuo arrivo in struttura.</h2>
              <div className="thank-you-box__btns-wrapper">
                <button type="button" className="thank-you-box__download-btn" onClick={ this.handleDownloadButton } disabled={ !this.props.voucher.token ? true : false}>{ !this.props.voucher.token ? 'Voucher scaricato' : 'Scarica voucher'}</button>
                <img className="thank-you-box__download-icon" src="/img/download-icon.svg" alt=""></img>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);