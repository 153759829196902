import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
/* Redux */
import store from '../../redux/store';
/* Helpers */
import history from '../../helpers/history'
/* Components */
import Home from '../Fase1/Home/index';
import ScegliEsperienza from '../Fase1/ScegliEsperienza/index';
import ScaricaVoucher from '../Fase1/ScaricaVoucher/index';
import ThankYou from '../Fase1/ThankYou/index';
import ControllaMail from '../Fase1/ControllaMail/index';
import ComeFunziona from '../Fase1/ComeFunziona/index';
/* CSS */
import './App.css';

class App extends Component {
  render() {
    return (
        <Provider store={ store } >
          <Router history={ history } >
            <Route path='/' exact component={ Home } />
            <Route path='/scegli-esperienza' exact component={ ScegliEsperienza } />
            <Route path='/scarica-voucher' exact component={ ScaricaVoucher } />
            <Route path='/thankyou' exact component={ ThankYou } />
            <Route path='/controlla-mail' exact component={ ControllaMail } />
            <Route path='/come-funziona' exact component={ ComeFunziona } />
          </Router>
        </Provider>
    );
  }
}

export default App;
