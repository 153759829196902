import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
/* CSS */
import './Header.css';

class Header extends Component {
  state = {
    balance: 0,
    menuMobile: {
      isOpen: false,
    }
  }

  handleMenuIconClick = () => {
    this.setState({
      menuMobile: {
        isOpen: !this.state.menuMobile.isOpen,
      }
    });
  }
  
  render() {
    let leftMenu;

    if (this.props.fase && this.props.fase === 2 && sessionStorage.getItem('token')) {
      leftMenu = (
        <Fragment>
          <button className="menu-btn">
            <i className="menu-btn__icon fas fa-bars" onClick={ this.handleMenuIconClick }></i>
          </button>

          <div className={`menu-mobile ${ this.state.menuMobile.isOpen ? 'menu-mobile--is-open' : '' }`}>
            <ul className="menu-mobile__list">
              <li className="menu-mobile__list-item"><Link to={'/ricerca'}>Cerca esperienze</Link></li>
              <li className="menu-mobile__list-item"><Link to={'/faq'}>Come Funziona</Link></li>
              <li className="menu-mobile__list-item"><Link to={'/profilo'}>Profilo</Link></li>
              <li className="menu-mobile__list-item"><button className="bottone-logout bottone-logout--mobile" type="button" onClick={this.handleLogoutClick}>Logout</button></li>
            </ul>
          </div>

          <div className="menu-desktop">
            <ul className="menu-desktop__list">
              <li className="menu-desktop__list-item"><Link to={'/ricerca'}>Cerca esperienze</Link></li>
              <li className="menu-desktop__list-item"><Link to={'/faq'}>Come Funziona</Link></li>
              <li className="menu-desktop__list-item"><Link to={'/profilo'}>Profilo</Link></li>
              <li className="menu-desktop__list-item"><Link to={'/scegli-esperienza-fase2'}>Scegli come divertirti, hai ancora <span className="menu-desktop__credits">{ this.state.balance }</span> crediti</Link></li>
            </ul>
            <button className="bottone-logout bottone-logout--desktop" type="button" onClick={this.handleLogoutClick}>Logout</button>
          </div>
        </Fragment>
      );
    } else {
      leftMenu = (
        <img className="container__logo_tickets" src={process.env.PUBLIC_URL + '/img/tickets.png'} alt=""></img>
      );
    }

    return (
      <header className="header">
        <Link className="header__logo-link" to='/'>
          <img className="header__logo" src="/img/logo.png" alt=""></img>
        </Link>
        <p className="header__sidebar-title">Scegli la tua esperienza</p>
      </header>
    );
  }
};

export default Header;
