import store from '../redux/store';
import axios from 'axios';
import history from './history';

const utils = {
  handleAPIRequest(settings, successCallback, errorCallback, catchCallback) {
    axios(settings)
      .then(response => {
        if (typeof response.data !== 'undefined' && response.data !== null) {
          switch (response.data.result) {
            case 'Error':
              this.showInConsole('warn', response.data.message);
              if (errorCallback) {
                errorCallback(response);
              }
              break;
            case 'OK':
              if (successCallback) {
                successCallback(response);
              }
              break;
            default:
                this.showInConsole('warn', 'Success case not managed');
          }
        }
      })
      .catch(error => {
        if (typeof error.response !== 'undefined' && error.response !== null) {
          switch (error.response.status) {
            case 400:
              this.showInConsole('error', 'Error 400: ', error.response.message);
              if (catchCallback) {
                catchCallback(error);
              } else {
                history.push('/controlla-mail');
              }
              break;
            case 401:
              this.showInConsole('error', 'Error 401: Unauthorized');
              localStorage.removeItem('token');
              history.push('/controlla-mail');
              break;
            case 500:
              this.showInConsole('error', 'Error 500: ', error.response.message);
              if (catchCallback) {
                catchCallback(error);
              } else {
                history.push('/controlla-mail');
              }
              break;
            default:
              this.showInConsole('warn', 'Error case not managed');
              history.push('/controlla-mail');
          }
        } else {
          this.showInConsole('warn', error.message);
        }
      });
  },
  setVenues(experiences) {
    let esperienze_gruppate = {};
    let esperienze_formattate = [];

    for (let key in experiences) {
      const esperienza = experiences[key];
      if (!esperienze_gruppate.hasOwnProperty(esperienza.rewardId + '-' + esperienza.venueId)) {
        esperienze_gruppate[esperienza.rewardId + '-' + esperienza.venueId] = [];
      }
      esperienze_gruppate[esperienza.rewardId + '-' + esperienza.venueId].push(esperienza);
    }

    for (let keyG in esperienze_gruppate) {
      const esperienza_gruppata = esperienze_gruppate[keyG];
      esperienze_formattate.push(esperienza_gruppata);
    }

    return esperienze_formattate;
  },
  getState() {
    return store.getState()
  },
  getUrlParameter(name) {
    let ret = '';
    let params_str = window.document.location.search.replace('?', '');
    let params = params_str.split('&');
    if (params && params.length > 0) {
      params.forEach(function(param_str) {
        let param = param_str.split('=');
        if (param[0] === name) {
          ret = param[1];
        }
      });
    }
    return ret;
  },
  correctUrlFormat(url) {
    if (url) {
      const regex = /^http(s)?:\/\/.*$/;
      if (!regex.test(url)) {
        return `http://${url.trim()}`;
      }
    }
    return url;
  },
  correctVoucherToPDF(url) {
    return url.replace('VoucherToPDF', 'VoucherToPDF2');
  },
  showInConsole(type, a, b) {
    const debug = true;

    if (debug) {
      switch (type) {
        case 'warn':
          console.warn(a, b);
          break;
        case 'error':
          console.error(a, b);
          break;
        default:
          console.log(a, b);
      }
    }
  },
  formatDate(date) {
    const day = date.substring(8, 10);
    const month = date.substring(5, 7);
    const year = date.substring(0, 4);

    return `${day}/${month}/${year}`;
  },
  formatToMDYDateFormat(date) {
    const day = date.substring(0, 2);
    const month = date.substring(3, 5);
    const year = date.substring(6, 10);

    return `${month}/${day}/${year}`;
  },
  compareArrayOfObjects(a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  },
  scrollToElement(selector) {
    if (selector) {
      selector.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  },
  formValidation: {
    isRequired(value) {
      if (!value) {
        return false;
      }
      return true;
    },
    containsOnlyText(value) {
      const re = /^[a-zA-ZÀ-ú\'']+$/;

      if (value && !re.test(value)) {
        return false;
      }
      return true;
    },
    containsOnlyDigits(value) {
      const re = /^(\+)?[0-9]+$/;

      if (value && !re.test(value)) {
        return false;
      }
      return true;
    },
    respectsMinLength(value, minLength) {
      if (value.length < minLength) {
        return false;
      }
      return true;
    },
    isDateValid(value) {
      const day = value.substring(0, 2);
      const month = value.substring(3, 5);
      const year = value.substring(6, 10);

      if (value && (Number(day) > 31 || Number(month) > 12 || Number(year) > new Date().getFullYear())) {
        return false;
      }
      return true;
    },
    isDateFormatValid(value) {
      const re = /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/;

      if (value && !re.test(value)) {
        return false;
      }
      return true;
    },
    isFiscalCodeValid(value) {
      const re = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$/;

      if (value && !re.test(value)) {
        return false;
      }
      return true;
    },
    isEmailValid(value) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (value && !re.test(value)) {
        return false;
      }
      return true;
    },
  }
}

export default utils;