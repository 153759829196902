import { OPEN_MODAL_CUSTOM } from "./action.types";
import { CLOSE_MODAL_CUSTOM } from "./action.types";
import { OPEN_MODAL_STAMP } from "./action.types";
import { CLOSE_MODAL_STAMP } from "./action.types";
import { OPEN_MODAL_CONFIRM } from "./action.types";
import { CLOSE_MODAL_CONFIRM } from "./action.types";
import { SET_MAP_COORDINATES } from "./action.types";
import { SET_MAP_EXPERIENCES } from "./action.types";
import { SET_MAP_ANNOTATIONS_LIST } from "./action.types";
import { SET_MAP_SELECTED_ANNOTATION } from "./action.types";
import { SET_VOUCHER_STRUCTURE } from "./action.types";
import { SET_VOUCHER_EXPERIENCE } from "./action.types";
import { SET_VOUCHER_TOKEN } from "./action.types";
import { SET_USER_PROFILE } from "./action.types";
import { SET_CODES } from "./action.types";
import { SET_IS_EXTERNAL_SEARCH } from "./action.types";
import { SET_EXPERIENCE_FILTER } from "./action.types";

const initialState = {
  modaleCustom: {
    isOpen: false,
  },
  modaleStamp: {
    isOpen: false,
  },
  modaleConferma: {
    isOpen: false,
  },
  filtri: {
    localizzazione: {},
    esperienze: {
      aliasCaricati: false,
    },
  },
  mappa: {
    coordinate: {
      Lat: 0,
      Lng: 0,
    },
    esperienze: [],
    annotations: {
      list: {},
      selected: null,
    }
  },
  voucher: {
    struttura: {},
    esperienza: {
      idVenue: '',
      idReward: '',
    },
    token: '',
  },
  user: {
    nome: '',
    email: '',
    saldo: 0,
    movimenti: [],
  },
  codici: [],
  helpers: {
    isExternalSearch: false,
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL_CUSTOM:
      return Object.assign({}, state, {
        modaleCustom: {
          ...state.modaleCustom,
          isOpen: true,
        },
      });
    case CLOSE_MODAL_CUSTOM:
      return Object.assign({}, state, {
        modaleCustom: {
          ...state.modaleCustom,
          isOpen: false,
        },
      });
    case OPEN_MODAL_STAMP:
      return Object.assign({}, state, {
        modaleStamp: {
          ...state.modaleStamp,
          isOpen: true,
        },
      });
    case CLOSE_MODAL_STAMP:
      return Object.assign({}, state, {
        modaleStamp: {
          ...state.modaleStamp,
          isOpen: false,
        },
      });
    case OPEN_MODAL_CONFIRM:
      return Object.assign({}, state, {
        modaleConferma: {
          ...state.modaleConferma,
          isOpen: true,
        },
      });
    case CLOSE_MODAL_CONFIRM:
      return Object.assign({}, state, {
        modaleConferma: {
          ...state.modaleConferma,
          isOpen: false,
        },
      });
    case SET_MAP_COORDINATES:
      const updatedCoordinates = { ...state.mappa.coordinate, ...action.payload };
      return Object.assign({}, state, {
        mappa: {
          ...state.mappa,
          coordinate: updatedCoordinates,
        },
      });
    case SET_MAP_EXPERIENCES:
      const updatedExperiences = action.payload;
      return Object.assign({}, state, {
        mappa: {
          ...state.mappa,
          esperienze: updatedExperiences,
        }
      });
    case SET_MAP_ANNOTATIONS_LIST:
      const updatedList = action.payload;
      return Object.assign({}, state, {
        mappa: {
          ...state.mappa,
          annotations: {
            ...state.mappa.annotations,
            list: updatedList,
          },
        }
      });
    case SET_MAP_SELECTED_ANNOTATION:
      const updatedAnnotationId = action.payload;
      return Object.assign({}, state, {
        mappa: {
          ...state.mappa,
          annotations: {
            ...state.mappa.annotations,
            selected: updatedAnnotationId,
          }
        }
      });
    case SET_VOUCHER_STRUCTURE:
      const updatedStructure = { ...state.voucher.struttura, ...action.payload };
      return Object.assign({}, state, {
        voucher: {
          ...state.voucher,
          struttura: updatedStructure,
        }
      });
    case SET_VOUCHER_EXPERIENCE:
      const updatedExperience = { ...state.voucher.esperienza, ...action.payload };
      return Object.assign({}, state, {
        voucher: {
          ...state.voucher,
          esperienza: updatedExperience,
        }
      });
    case SET_VOUCHER_TOKEN:
      const token = action.payload;
      return Object.assign({}, state, {
        voucher: {
          ...state.voucher,
          token,
        }
      });
    case SET_USER_PROFILE:
      const userProfile = action.payload;
      return Object.assign({}, state, {
        user: userProfile
      });
    case SET_CODES:
      const codes = action.payload;
      return Object.assign({}, state, {
        codici: codes
      });
    case SET_IS_EXTERNAL_SEARCH:
      const isExternalSearch = action.payload;
      return Object.assign({}, state, {
        helpers: {
          ...state.helpers,
          isExternalSearch,
        }
      });
    case SET_EXPERIENCE_FILTER:
      const updatedExperienceFilter = { ...state.filtri.esperienze, ...action.payload };
      return Object.assign({}, state, {
        filtri: {
          ...state.filtri,
          esperienze: updatedExperienceFilter,
        }
      });
    default:
      return state;
  }
}

export default reducer;
