import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
/* Helpers */
import config from './helpers/config';
/* Components */
import App from './components/App/index';

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={ config.reCaptchaKey }
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
