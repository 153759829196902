import { OPEN_MODAL_CUSTOM } from "./action.types";
import { CLOSE_MODAL_CUSTOM } from "./action.types";
import { OPEN_MODAL_STAMP } from "./action.types";
import { CLOSE_MODAL_STAMP } from "./action.types";
import { OPEN_MODAL_CONFIRM } from "./action.types";
import { CLOSE_MODAL_CONFIRM } from "./action.types";
import { SET_MAP_COORDINATES } from "./action.types";
import { SET_MAP_EXPERIENCES } from "./action.types";
import { SET_MAP_ANNOTATIONS_LIST } from "./action.types";
import { SET_MAP_SELECTED_ANNOTATION } from "./action.types";
import { SET_VOUCHER_STRUCTURE } from "./action.types";
import { SET_VOUCHER_EXPERIENCE } from "./action.types";
import { SET_VOUCHER_TOKEN } from "./action.types";
import { SET_USER_PROFILE } from "./action.types";
import { SET_CODES } from "./action.types";
import { SET_IS_EXTERNAL_SEARCH } from "./action.types";
import { SET_EXPERIENCE_FILTER } from "./action.types";

/*** ACTION CREATORS ***/
export function openModalCustom(payload) {
  return { type: OPEN_MODAL_CUSTOM, payload };
}
export function closeModalCustom(payload) {
  return { type: CLOSE_MODAL_CUSTOM, payload };
}
export function openModalStamp(payload) {
  return { type: OPEN_MODAL_STAMP, payload };
}
export function closeModalStamp(payload) {
  return { type: CLOSE_MODAL_STAMP, payload };
}
export function openModalConfirm(payload) {
  return { type: OPEN_MODAL_CONFIRM, payload };
}
export function closeModalConfirm(payload) {
  return { type: CLOSE_MODAL_CONFIRM, payload };
}

export function setMapCoordinates(payload) {
  return { type: SET_MAP_COORDINATES, payload };
}

export function setMapExperiences(payload) {
  return { type: SET_MAP_EXPERIENCES, payload };
}

export function setMapAnnotationsList(payload) {
  return { type: SET_MAP_ANNOTATIONS_LIST, payload };
}

export function setMapSelectedAnnotation(payload) {
  return { type: SET_MAP_SELECTED_ANNOTATION, payload };
}

export function setVoucherStructure(payload) {
  return { type: SET_VOUCHER_STRUCTURE, payload };
}

export function setVoucherExperience(payload) {
  return { type: SET_VOUCHER_EXPERIENCE, payload };
}

export function setVoucherToken(payload) {
  return { type: SET_VOUCHER_TOKEN, payload };
}

export function setUserProfile(payload) {
  return { type: SET_USER_PROFILE, payload };
}

export function setCodes(payload) {
  return { type: SET_CODES, payload };
}

export function setIsExternalSearch(payload) {
  return { type: SET_IS_EXTERNAL_SEARCH, payload };
}

export function setExperienceFilter(payload) {
  return { type: SET_EXPERIENCE_FILTER, payload };
}
