import React, { Component, Fragment } from 'react';
/* Import CSS */
import './Dropdown.css';
import './Registration.css';

class Registration extends Component {
  state = {
    isIE11: false,
  }

  componentDidMount() {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.setState({
        isIE11: true,
      })
    }
  }

  render() {
    const { isIE11 } = this.state;

    return (
      <Fragment>
        <div className='opaque-overlay opaque-overlay--center-content opaque-overlay--is-active scarica-voucher__opaque-overlay'>
          <div className={`registration-box scarica-voucher__registration-box ${ isIE11 ? 'scarica-voucher__registration-box--is-IE11' : '' }`}>
            <h1 className="registration-box__title">Ottima scelta!</h1>
            <h2 className="registration-box__subtitle">Hai scelto la tua esperienza 2x1 da condividere con chi preferisci! Per aiutarci a conoscerti meglio ti chiediamo ancora qualche minuto prima di scaricare il tuo voucher.</h2>
            <h3 className="registration-box__form-title">Compila con i tuoi dati</h3>
            <form className="registration-box__form" onSubmit={ this.props.handleSubmit }>
              <input className="registration-box__birth-date" type="text" name="birthDate" placeholder="Data di nascita  __/__/____" onChange={this.props.handleChange}></input>
              {this.props.currentState.errors.birthDateError ? <div className="error-msg">{this.props.currentState.errors.birthDateError}</div> : null}
              <div className="dropdown registration-box__job" data-name="job">
                <button type="button" className="dropdown__btn" onClick={ this.props.handleDropdownButton }><span className="dropdown__btn-text">{ this.props.currentState.user.job.name }</span><i className={`dropdown__btn-icon fas fa-chevron-${this.props.currentState.openDropdown === 'job' ? 'up' : 'down'} fa-2x`}></i></button>
                <div className={`dropdown__content ${this.props.currentState.openDropdown === 'job' ? 'dropdown__content--is-active' : ''}`}>
                  <p className="dropdown__option" data-value="PIVA" onClick={ this.props.handleDropdownOption }>Libero professionista</p>
                  <p className="dropdown__option" data-value="IMPI" onClick={ this.props.handleDropdownOption }>Impiegato</p>
                  <p className="dropdown__option" data-value="DIRI" onClick={ this.props.handleDropdownOption }>Dirigente</p>
                  <p className="dropdown__option" data-value="STUD" onClick={ this.props.handleDropdownOption }>Studente</p>
                  <p className="dropdown__option" data-value="PENS" onClick={ this.props.handleDropdownOption }>Pensionato o impossibilitato al lavoro</p>
                  <p className="dropdown__option" data-value="CASA" onClick={ this.props.handleDropdownOption }>Casalingo/a</p>
                  <p className="dropdown__option" data-value="DISO" onClick={ this.props.handleDropdownOption }>Disoccupato/a</p>
                </div>
              </div>
              {this.props.currentState.errors.jobError ? <div className="error-msg">{this.props.currentState.errors.jobError}</div> : null}
              <p className="registration-box__form-text">Per continuare a migliorare il servizio, indicaci almeno un’area di interesse:</p>
              <div className="dropdown registration-box__hobby" data-name="hobby">
                <button type="button" className="dropdown__btn" onClick={ this.props.handleDropdownButton }><span className="dropdown__btn-text">{ this.props.currentState.user.hobby.name }</span><i className={`dropdown__btn-icon fas fa-chevron-${this.props.currentState.openDropdown === 'hobby' ? 'up' : 'down'} fa-2x`}></i></button>
                <div className={`dropdown__content ${this.props.currentState.openDropdown === 'hobby' ? 'dropdown__content--is-active' : ''}`}>
                  <p className="dropdown__option" data-value="CINE" onClick={ this.props.handleDropdownOption }>Cinema</p>
                  <p className="dropdown__option" data-value="EVEN" onClick={ this.props.handleDropdownOption }>Eventi (musica, festival, ecc.)</p>
                  <p className="dropdown__option" data-value="COOK" onClick={ this.props.handleDropdownOption }>Gastronomia</p>
                  <p className="dropdown__option" data-value="WELL" onClick={ this.props.handleDropdownOption }>Wellness</p>
                  <p className="dropdown__option" data-value="TRAV" onClick={ this.props.handleDropdownOption }>Viaggi</p>
                </div>
              </div>
              {this.props.currentState.errors.hobbyError ? <div className="error-msg">{this.props.currentState.errors.hobbyError}</div> : null}
              {/* <div className="registration-box__privacy-circular">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et eveniet, similique doloribus, enim porro, iure quia suscipit consectetur ratione consequatur doloremque eum sapiente asperiores cumque praesentium! Laboriosam eos nisi repudiandae non sint consequatur necessitatibus. Consequuntur voluptate dolorem laudantium beatae accusamus fuga ea porro ratione eos, eius aut facere a suscipit vitae provident atque nihil totam deserunt nam officiis asperiores hic quibusdam? Est vel nesciunt aliquam totam quae pariatur, quam quia possimus fugiat autem ex quisquam. Ad, voluptatum debitis deserunt cumque nulla nesciunt fuga? Laborum voluptatem quisquam laboriosam aperiam pariatur esse adipisci impedit velit sequi. Ipsa quasi saepe repudiandae ipsam ipsum!</div>
              <div className="registration-box__privacy-wrapper">
                <input className="registration-box__privacy-radio" type="radio" name="privacy" value="true" onChange={this.props.handleChange}></input>
                <p className="registration-box__privacy-text">Accetto il trattamento per le finalità indicate</p>
              </div>
              {this.props.currentState.errors.privacyError ? <div className="error-msg">{this.props.currentState.errors.privacyError}</div> : null} */}
              <button type="submit" className="registration-box__submit-btn" disabled={ this.props.currentState.btnClicked }><i className="fas fa-chevron-right fa-2x"></i></button>
              {this.props.currentState.serverError ? <div className="error-msg error-msg--server">{this.props.currentState.serverError}</div> : null}
              {this.props.currentState.serverSuccess ? <div className="success-msg success-msg--server">{this.props.currentState.serverSuccess}</div> : null}
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Registration;