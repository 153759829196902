export const OPEN_MODAL_CUSTOM = "OPEN_MODAL_CUSTOM";
export const CLOSE_MODAL_CUSTOM = "CLOSE_MODAL_CUSTOM";
export const OPEN_MODAL_STAMP = "OPEN_MODAL_STAMP";
export const CLOSE_MODAL_STAMP = "CLOSE_MODAL_STAMP";
export const OPEN_MODAL_CONFIRM = "OPEN_MODAL_CONFIRM";
export const CLOSE_MODAL_CONFIRM = "CLOSE_MODAL_CONFIRM";
export const SET_MAP_COORDINATES = "SET_MAP_COORDINATES";
export const SET_MAP_EXPERIENCES = "SET_MAP_EXPERIENCES";
export const SET_MAP_ANNOTATIONS_LIST = "SET_MAP_ANNOTATIONS_LIST";
export const SET_MAP_SELECTED_ANNOTATION = "SET_MAP_SELECTED_ANNOTATION";
export const SET_VOUCHER_STRUCTURE = "SET_VOUCHER_STRUCTURE";
export const SET_VOUCHER_EXPERIENCE = "SET_VOUCHER_EXPERIENCE";
export const SET_VOUCHER_TOKEN = "SET_VOUCHER_TOKEN";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_CODES = "SET_CODES";
export const SET_IS_EXTERNAL_SEARCH = "SET_IS_EXTERNAL_SEARCH";
export const SET_EXPERIENCE_FILTER = "SET_EXPERIENCE_FILTER";
